import { createSlice } from '@reduxjs/toolkit'

export const nodeSlice = createSlice({
    name: 'node',
    initialState: {
        loaded: false,
        loading: false,
        gid: -1,
        nid: -1,
        details: {},
    },
    reducers: {
        loadNode: (state,{payload}) => {
            state.loaded = false
            state.loading = true
            state.gid = payload.gid
            state.nid = payload.nid
        },

        loadedNode: (state, {payload})=>{
            state.loaded = true
            state.loading = false
            state.details = []
            Object.entries(payload).forEach(([id,property])=>{
                state.details.push(property)
            })
            state.details = payload
        },

    }
})

export const {
    loadNode, loadedNode,

} = nodeSlice.actions

export default nodeSlice.reducer
