import { put } from 'redux-saga/effects';

import {loadedNode} from '../slices/node'
import {recaderoRequest} from "./recaderoApi";

export function * nodeLoad(action){

    const result = yield recaderoRequest("node_details", {gateway_id: action.payload.gid, node_id: action.payload.nid})

    if (!result){
        yield put(loadedNode({}))
    } else {
        yield put(loadedNode(result.details))
    }

}
