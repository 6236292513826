import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './sagas/rootSaga'
import logger from 'redux-logger'
import userLoginReducer from './slices/userLogin'
import gatewayListReducer, {loadGatewayList} from './slices/gatewayList'
import nodeListReducer, {loadNodeList} from './slices/nodeList'
import sensorListReducer from './slices/sensorList'
import selectedGatewayReducer from './slices/selectedGateway'
import nodeReducer from './slices/node'
import {useParams} from "react-router-dom";

let store = {};

export default function configureAppStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware()

    const reducer = {
        userLogin: userLoginReducer,
        gatewayList: gatewayListReducer,
        nodeList: nodeListReducer,
        sensorList: sensorListReducer,
        selectedGateway: selectedGatewayReducer,
        node: nodeReducer,
    }

    const my_middleware = [
        sagaMiddleware,
        logger,
    ]

    const enhancers = [
    ]

    store = configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false }), ...my_middleware],
        preloadedState,
        enhancers,
        devTools: process.env.NODE_ENV !== 'production',
    })

    sagaMiddleware.run(rootSaga)

    return store
}

export function dispatchToStore(action){

    store.dispatch(action)
}