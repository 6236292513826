import { put } from 'redux-saga/effects';
import {recaderoRequest} from "./recaderoApi";
import {loadedSensorList, updateSensorListDone} from "../slices/sensorList";


export function * sensorListLoad(action){

    const requestData = {"gateway_id": action.payload.gid, "node_id": action.payload.nid}

    // execute api request
    const response = yield recaderoRequest("sensor", "list", requestData)

    if (response){
        yield put(loadedSensorList(response))
    } else {
        yield put(loadedSensorList({"none":"empty"}))
    }

}

export function * sensorListUpdate(action){

    const requestData = {
        "gateway_id": action.payload.gid,
        "node_id": action.payload.nid,
        "sensor_config": action.payload.sensors,
    }

    // execute api request
    const response = yield recaderoRequest("sensor", "update", requestData)

    if (response){
        yield put(updateSensorListDone(response))
    } else {
        yield put(updateSensorListDone({"none":"empty"}))
    }

}
