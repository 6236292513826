import React from 'react';
import {useSelector} from "react-redux";
import {
    Button,
    Card, CardContent, Grid, TextField,
} from "@mui/material";

export default function UserAccountPage() {

    const userLogin = useSelector(state => state.userLogin)

    const user = userLogin.user
    return (
        <Card>
            <CardContent>
                <h1>My Account</h1>

                <Grid container direction={'column'} spacing={2} justifyContent={'center'}  alignItems="center">
                    <Grid item>
                        <TextField
                            disabled
                            id="outlined-disabled"
                            label="user"
                            defaultValue={user.name}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            disabled
                            id="outlined-disabled"
                            label="first name"
                            defaultValue={user.firstName}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            disabled
                            id="outlined-disabled"
                            label="last name"
                            defaultValue={user.lastName}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            disabled
                            id="outlined-disabled"
                            label="email"
                            defaultValue={user.email}
                        />
                    </Grid>

                    <Grid item>
                        <Button variant={"contained"} color={"primary"} disabled>Apply</Button><Button variant={"contained"} color={"secondary"} disabled>Cancel</Button>
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    );

};

