import { put } from 'redux-saga/effects';
import {loadedGatewayList, updateGatewayListModeDone} from '../slices/gatewayList'
import {recaderoRequest} from "./recaderoApi";
import {updateNodeListPropDone} from "../slices/nodeList";


export function * gatewayListLoad(action){

    const requestData = {}

    // execute api request
    const response = yield recaderoRequest("gateway", "list", requestData)

    if (response){
        yield put(loadedGatewayList(response))
    } else {
        yield put(loadedGatewayList({"none":"empty"}))
    }

}

export function * gatewayListUpdateMode(action){

    const requestData = {
        "gateway_id": action.payload.gid,
        "mode": action.payload.mode,
    }

    // execute api request
    const response = yield recaderoRequest("gateway", "update-mode", requestData)

    if (response){
        yield put(updateGatewayListModeDone(response))
    } else {
        yield put(updateGatewayListModeDone(response))
    }

}
