import { put } from 'redux-saga/effects';
import {loginOk, loginFailed, loginLoadFinished} from '../slices/userLogin'
import CryptoJS from "crypto-js"
import {clearSessionId, recaderoRequest} from "./recaderoApi";

function handle_loginResponse(response){

}

export function * loadLogin(action){

    // request user info -- we try with last valid session from local store
    const response = yield recaderoRequest("user", "continue", {})

    if (response){
        // if session is valid - we can login the last user
        yield put(loginOk(response.user))
    }

    yield put(loginLoadFinished())
}

export function * userLogin(action){

    // encode user pass
    const username = action.payload.name
    const password = CryptoJS.MD5(action.payload.pass).toString()

    // set user data
    const requestData = {
        "user_name": username,
        "user_pass": password,
    }

    // execute api request
    const response = yield recaderoRequest("user", "login", requestData)

    if (!response){
        // do something on error
        console.log("login failed")
        console.log(response.status)
        yield put(loginFailed())
        return
    }

    try {
        // get data from response stream
        yield put(loginOk(response.user))
    } catch (e) {
        console.log(response)
        console.log(e)
        yield put(loginFailed())
    }

}

export function * userLogout(action) {
    clearSessionId()
}
