import { createTheme } from '@mui/material/styles';

export const theme = createTheme({

    palette: {
        type: 'light',
        primary: {
            main: '#ffd54f',
        },
        secondary: {
            main: '#dcedc8',
        },
        background: {
            default: '#555555'
        },

    },
    shape: {
        borderRadius: 6,
    },

    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    WebkitFontSmoothing: 'auto',
                },
            },
        }
    },

    status: {
        present: '#15ad61',
        not_present: '#ad4d15',

        connected: '#1b41cc',
        not_connected: '#adafb8',
    }

});
