import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {loadGatewayList} from "../store/slices/gatewayList";
import {Paper} from "@mui/material";
import {Link} from "react-router-dom";


export default function AnotherPage() {

    const userLogin = useSelector(state => state.userLogin)
    const gatewayList = useSelector(state => state.gatewayList)

    const dispatch = useDispatch()

    useEffect(() => {
        // Update the document title using the browser API
        dispatch(loadGatewayList())
    }, []);

    return (
        <Paper>

            <div className="col-md-6 col-md-offset-3">

                <h1>Hi</h1>
                <p>You're logged in with React!!</p>
                <p>
                    {userLogin.user.email}
                    <Link variant="outlined" to="/other" className="btn btn-link">other</Link>
                </p>
            </div>
        </Paper>
    );

};

