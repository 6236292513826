import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
    name: 'userLogin',
    initialState: {
        isLoading: false,
        isLoaded: false,
        loggedIn: false,
        loggingIn: false,
        user: {},
        input: {},
        message: null
    },
    reducers: {
        loginLoad: (state) => {
            state.isLoading = true
            state.isLoaded = false
        },
        loginLoadFinished: (state, {payload}) => {
            state.isLoading = false
            state.isLoaded = true
        },

        loginUser: (state,{payload}) => {
            state.loggingIn = true
            state.loggedIn = false
        },

        loginOk: (state, {payload})=>{
            state.loggingIn = false
            state.loggedIn = true
            state.input = {}
            state.user.name = payload.name
            state.user.firstName = payload.firstName
            state.user.lastName = payload.lastName
            state.user.email = payload.email

            state.message = "login ok"
        },

        loginFailed: (state)=>{
            state.loggingIn = false
            state.loggedIn = false
            state.user = {}
            state.input = {}

            state.message = "login failed"
        },

        logoutUser: (state )=>{
            state.loggedIn = false
            state.loggingIn = false
            state.user = {}
            state.input = {}
            state.message = null
        },

        loginSetUserName:(state, {payload}) => {
            console.log(payload)
            state.input.name = payload
        },
        loginSetPassword:(state, {payload}) => {
            console.log(payload)
            state.input.pass = payload
        },

    }
})

export const {
    loginLoad, loginLoadFinished,
    loginUser, logoutUser,
    loginOk,loginFailed,
    loginSetUserName, loginSetPassword,
} = loginSlice.actions

export default loginSlice.reducer
