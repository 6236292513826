import { createSlice } from '@reduxjs/toolkit'
import {default_current_settings} from "../../utils/defaults";


export const selectedGatewaySlice = createSlice({
    name: 'selectedGatewayReducer',
    initialState: {
        gid: -1,
        name: "",
        serial_number: "",
        last_modified: "",
        current_settings: default_current_settings,
        new_settings: {},
    },

    reducers: {
        requestGetGateway: (state,{payload}) => {
            state.gid = payload.gid
            state.current_settings = default_current_settings
        },

        responseGetGateway: (state, {payload})=>{

            try {
                state.name = payload.name
                state.serial_number = payload.serial_number
                state.last_modified = payload.last_modified

                const current_settings = JSON.parse(payload.current_global_settings)
                console.error(current_settings)

                // bluetooth
                state.current_settings.bluetooth.mode = current_settings.bluetooth.mode
                // data
                state.current_settings.data.log_period_s = current_settings.data["log_period[s]"]
                state.current_settings.data.to_console = current_settings.data.to_console
                state.current_settings.data.to_file = current_settings.data.to_file

                // uplink.data
                state.current_settings.uplink.data.fluentbit_enabled = current_settings.uplink.data.fluentbit_enabled
                state.current_settings.uplink.data.fluentbit_storage_location = current_settings.uplink.data.fluentbit_storage_location
                state.current_settings.uplink.data.fluentbit_storage_total_limit_size = current_settings.uplink.data.fluentbit_storage_total_limit_size
                state.current_settings.uplink.data.upload_interval_sec = current_settings.uplink.data.upload_interval_sec
                state.current_settings.uplink.data.upload_raw_values = current_settings.uplink.data.upload_raw_values

                // uplink.debug
                state.current_settings.uplink.debug.log_to_console = current_settings.uplink.debug.log_to_console

                // uplink.hardware
                state.current_settings.uplink.hardware.use_sim_module = current_settings.uplink.hardware.use_sim_module
                state.current_settings.uplink.hardware.use_wifi_upload = current_settings.uplink.hardware.use_wifi_upload

                // uplink.transfer
                state.current_settings.uplink.transfer.sync_global_settings = current_settings.uplink.transfer.sync_global_settings
                state.current_settings.uplink.transfer.sync_log = current_settings.uplink.transfer.sync_log
                state.current_settings.uplink.transfer.sync_node_settings = current_settings.uplink.transfer.sync_node_settings
                state.current_settings.uplink.transfer.sync_period_msec = current_settings.uplink.transfer.sync_period_msec
                state.current_settings.uplink.transfer.sync_remote_commands = current_settings.uplink.transfer.sync_remote_commands
                state.current_settings.uplink.transfer.use_gzip_upload = current_settings.uplink.transfer.use_gzip_upload

                // uplink.endpoint
                try {
                    state.current_settings.uplink.endpoint.api_key = current_settings.uplink.endpoint.api_key
                    state.current_settings.uplink.endpoint.host = current_settings.uplink.endpoint.host
                    state.current_settings.uplink.endpoint.port = current_settings.uplink.endpoint.port
                    state.current_settings.uplink.endpoint.uri = current_settings.uplink.endpoint.uri
                } catch (e){
                    console.warn("responseGetGateway -- current settings does not have section endpoint")
                }


                let new_settings = {}
                try {
                    // new node settings can be null
                    new_settings = JSON.parse(payload.new_global_settings)
                } catch (e) {
                    console.log("responseGetGateway -- empty new node settings")
                }
                state.new_settings = new_settings

            } catch (e) {
                console.warn("responseGetGateway() -- failed to update " + String(e))
                console.log(payload)
            }


        },

    }
})

export const {
    requestGetGateway, responseGetGateway,

} = selectedGatewaySlice.actions

export default selectedGatewaySlice.reducer
