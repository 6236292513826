
export const default_current_settings = {
    bluetooth: {
        mode: ""
    },
    data: {
        log_period_s: 0,
        to_console: false,
        to_file: false,
    },
    uplink:{
        data: {
            fluentbit_enabled: false,
            fluentbit_storage_location: "",
            fluentbit_storage_total_limit_size: "",
            labels: {
                project_id: "",
                tenant_id: "",
            },
            upload_interval_sec: 0,
            upload_raw_values: false,
        },
        debug: {
            log_to_console: false,
        },
        hardware: {
            use_sim_module: false,
            use_wifi_upload: false,
        },
        transfer: {
            sync_global_settings: false,
            sync_log: false,
            sync_node_settings: false,
            sync_period_msec: 0,
            sync_remote_commands: false,
            use_gzip_upload: false,
        },
        endpoint: {
            api_key: "",
            host: "",
            port: "",
            uri: "",
        }
    }
}