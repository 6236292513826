import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    Card,
    CardContent, Divider,
    FormControl, FormControlLabel,
    FormHelperText,
    Input,
    InputLabel,
    TextField,
    Typography
} from "@mui/material";
import Switch from '@mui/material/Switch'
import {Link as RouterLink, useParams} from "react-router-dom";
import {requestGetGateway} from "../../store/slices/selectedGateway";
import {dispatchToStore} from "../../store";
import Box from "@mui/material/Box";

function WaitForApplySettings(props){

    return (
        <Box>
            <Typography variant={"h4"}>Applying settings ... please wait</Typography>
        </Box>
    )
}

function EditSettings(props){

    const settings = props.settings
    const setSettings = props.set_settings
    const onApplySettings = props.onApplySettings

    const [modified, setModified] = useState(false)
    const [bluetoothModeChecked, setBluetoothModeChecked] = useState(false)


    useEffect(()=>{
        if (settings.bluetooth.mode === "RUN"){
            setBluetoothModeChecked(true)
        }

    }, [settings.bluetooth.mode])

    const handleBluetoothMode = (e) => {
        setBluetoothModeChecked(e.target.checked)
        const mode = e.target.checked?"RUN":"CONFIG"
        setSettings({...settings, bluetooth: {"mode":mode}})
        setModified(true)
    }

    const handleDataChange = (e) => {
        let value = null
        switch (e.target.name){
            case "bool": value = e.target.checked; break;
            default:
                value = e.target.value
        }
        console.log(e.target.id + " of type [" + e.target.name + "] has new value " + value)
        setSettings({...settings, data:{...settings.data, [e.target.id]:value}})
        setModified(true)
    }

    const handleUplinkDataChange = (e) => {
        let value = null
        switch (e.target.name){
            case "bool": value = e.target.checked; break;
            default:
                value = e.target.value
        }
        console.log(e.target.id + " of type [" + e.target.name + "] has new value " + value)
        setSettings({...settings, uplink:{...settings.uplink, data:{...settings.uplink.data, [e.target.id]:value}}})
        setModified(true)
    }

    const handleUplinkDebugChange = (e) => {
        let value = null
        switch (e.target.name){
            case "bool": value = e.target.checked; break;
            default:
                value = e.target.value
        }
        console.log(e.target.id + " of type [" + e.target.name + "] has new value " + value)
        setSettings({...settings, uplink:{...settings.uplink, debug:{...settings.uplink.debug, [e.target.id]:value}}})
        setModified(true)
    }

    const handleUplinkHardwareChange = (e) => {
        let value = null
        switch (e.target.name){
            case "bool": value = e.target.checked; break;
            default:
                value = e.target.value
        }
        console.log(e.target.id + " of type [" + e.target.name + "] has new value " + value)
        setSettings({...settings, uplink:{...settings.uplink, hardware:{...settings.uplink.hardware, [e.target.id]:value}}})
        setModified(true)
    }

    const handleUplinkTransferChange = (e) => {
        let value = null
        switch (e.target.name){
            case "bool": value = e.target.checked; break;
            default:
                value = e.target.value
        }
        console.log(e.target.id + " of type [" + e.target.name + "] has new value " + value)
        setSettings({...settings, uplink:{...settings.uplink, transfer:{...settings.uplink.transfer, [e.target.id]:value}}})
        setModified(true)
    }

    const handleUplinkEndpointChange = (e) => {
        let value = null
        switch (e.target.name){
            case "bool": value = e.target.checked; break;
            default:
                value = e.target.value
        }
        console.log(e.target.id + " of type [" + e.target.name + "] has new value " + value)
        setSettings({...settings, uplink:{...settings.uplink, endpoint:{...settings.uplink.endpoint, [e.target.id]:value}}})
        setModified(true)
    }

    return (
        <form >
            <Box sx={{'& > :not(style)': { m: 1, width: '25ch' },}}>

                <Typography variant={"h5"}>Bluetooth</Typography>
                <FormControlLabel control={<Switch checked={bluetoothModeChecked} onChange={handleBluetoothMode} />} label={settings.bluetooth.mode} />

                <Typography variant={"h5"}>Data</Typography>
                <TextField id="log_period_s" type="number" variant="outlined" label="Logging period"  value={settings.data.log_period_s} onChange={handleDataChange}/>
                <FormControlLabel control={<Switch id="to_console" name="bool" checked={settings.data.to_console} onChange={handleDataChange} />} label={"Log to console"} />
                <FormControlLabel control={<Switch id="to_file" name="bool" checked={settings.data.to_file} onChange={handleDataChange} />} label={"Log to file"} />



                <Typography variant={"h5"}>Uplink</Typography>

                <Typography variant={"h6"}>Data</Typography>
                    <FormControlLabel control={<Switch id="fluentbit_enabled" name="bool" checked={settings.uplink.data.fluentbit_enabled} onChange={handleUplinkDataChange} />} label={"Enable fluentbit"} />
                    <FormControlLabel control={<Switch id="upload_raw_values" name="bool" checked={settings.uplink.data.upload_raw_values} onChange={handleUplinkDataChange} />} label={"Upload raw data"} />
                    <TextField id="fluentbit_storage_location" variant="outlined" label="Temporary storage location"  value={settings.uplink.data.fluentbit_storage_location} onChange={handleUplinkDataChange}/>
                    <TextField id="fluentbit_storage_total_limit_size" variant="outlined" label="Total storage limit"  value={settings.uplink.data.fluentbit_storage_total_limit_size} onChange={handleUplinkDataChange}/>


                <Typography variant={"h6"}>debug</Typography>
                    <FormControlLabel control={<Switch id="log_to_console" name="bool" checked={settings.uplink.debug.log_to_console} onChange={handleUplinkDebugChange} />} label={"Log traffic info to console"} />

                <Typography variant={"h6"}>hardware</Typography>
                    <FormControlLabel control={<Switch id="use_sim_module" name="bool" checked={settings.uplink.hardware.use_sim_module} onChange={handleUplinkHardwareChange} />} label={"Use sim module for upload"} />
                    <FormControlLabel control={<Switch id="use_wifi_upload" name="bool" checked={settings.uplink.hardware.use_wifi_upload} onChange={handleUplinkHardwareChange} />} label={"Use wifi for upload"} />

                <Typography variant={"h6"}>transfer</Typography>
                    <FormControlLabel control={<Switch id="sync_global_settings" name="bool" checked={settings.uplink.transfer.sync_global_settings} onChange={handleUplinkTransferChange} />} label={"Sync global settings"} />
                    <FormControlLabel control={<Switch id="sync_log" name="bool" checked={settings.uplink.transfer.sync_log} onChange={handleUplinkTransferChange} />} label={"Sync logs"} />
                    <FormControlLabel control={<Switch id="sync_node_settings" name="bool" checked={settings.uplink.transfer.sync_node_settings} onChange={handleUplinkTransferChange} />} label={"Sync node settings"} />
                    <FormControlLabel control={<Switch id="sync_remote_commands" name="bool" checked={settings.uplink.transfer.sync_remote_commands} onChange={handleUplinkTransferChange} />} label={"Sync remote commands"} />
                    <FormControlLabel control={<Switch id="use_gzip_upload" name="bool" checked={settings.uplink.transfer.use_gzip_upload} onChange={handleUplinkTransferChange} />} label={"Sync using gzip"} />
                    <TextField id="sync_period_msec" type="number" variant="outlined" label="Sync period"  value={settings.uplink.transfer.sync_period_msec} onChange={handleUplinkTransferChange}/>

                <Typography variant={"h6"}>endpoint</Typography>
                    <TextField id="api_key" variant="outlined" label="api_key" value={settings.uplink.endpoint.api_key} onChange={handleUplinkEndpointChange}/>
                    <TextField id="host" variant="outlined" label="host" value={settings.uplink.endpoint.host} onChange={handleUplinkEndpointChange}/>
                    <TextField id="port" variant="outlined" label="port" value={settings.uplink.endpoint.port} onChange={handleUplinkEndpointChange}/>
                    <TextField id="uri" variant="outlined" label="uri" value={settings.uplink.endpoint.uri} onChange={handleUplinkEndpointChange}/>


                <br />

                <Button variant={"contained"} color={"primary"} disabled={!modified} type="submit" onClick={()=>{onApplySettings()}}>Apply</Button>
                <Button variant={"contained"} color={"secondary"} component={RouterLink} to="/gateways" >Cancel</Button>
            </Box>


        </form>
    )
}

export default function GatewaySettingsPage() {

    const { gid } = useParams()

    const gateway = useSelector(state => state.selectedGateway)

    const [settings, setSettings] = useState({})
    const [applyingSettings, setApplyingSettings] = useState(false)

    useEffect(() => {
        // Update the document title using the browser API
        dispatchToStore(requestGetGateway({gid:gid}))

    }, []);

    useEffect(()=>{
        console.log("presetting global settings")

        setSettings(gateway.current_settings)

        if (Object.keys(gateway.new_settings).length !== 0){
            // we are still applying settings
            setApplyingSettings(true)
        }

    }, [gateway])

    const onApplySettings = () => {
        setApplyingSettings(true)
    }

    // dont render anything, if settings not fully loaded
    if (Object.keys(settings).length === 0){
        return(<></>)
    }
    console.log(settings)

    // \TODO render form
    // https://www.botsplash.com/post/convert-your-json-to-forms-in-react
    return (
        <Card>
            <CardContent >
                <h1>Gateway Settings - {gateway.name}</h1>

                {applyingSettings?
                    <WaitForApplySettings />
                    :
                    <EditSettings settings={settings} set_settings={setSettings} onApplySettings={onApplySettings}/>
                }


            </CardContent>
        </Card>
    );

};

