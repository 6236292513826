import * as React from "react";
import * as ReactDOM from "react-dom";
import {createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';

import { theme } from './style/theme'
import App from './App';


ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </StyledEngineProvider>,

    document.querySelector("#root")
);