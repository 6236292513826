import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Grid, IconButton, Typography} from "@mui/material";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {loginUser, logoutUser} from "../store/slices/userLogin";
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import AppMenu from "./AppMenu";
import UserMenu from "./UserMenu";
import {config} from "../config";

function UserDetails(props){
    const user = props.user

    if (!user.name){
        return(
            <div>missing user details</div>
        )
    }

    return (
        <div>
            {user.name}
        </div>
    )
}

function login(){
    const dispatch = useDispatch();
    return (
        <Button color="inherit" component={RouterLink} to={'/login'}>Login</Button>
     )
}

function logout(){
    return (
        <UserMenu />
    )
}


export default function TitleBar(props) {

    const userLogin = useSelector(state => state.userLogin)

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color='secondary'>
                <Toolbar>
                    <AppMenu />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        webConsole {config.version}
                    </Typography>
                    {userLogin.loggedIn?logout():login()}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
