import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../store/slices/userLogin";
import {Card, CardContent} from "@mui/material";


export default function LogoutPage() {
    const dispatch = useDispatch();

    const userLogin = useSelector(state => state.userLogin)
    if (userLogin.loggedIn){
        dispatch(logoutUser())
    }

    return (
        <Card>
            <CardContent>
                <h1>Logout</h1>
                <p>You are logged out now</p>

            </CardContent>
        </Card>
    );

};

