import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loginUser, loginSetUserName, loginSetPassword} from "../../store/slices/userLogin";
import {dispatchToStore} from "../../store";
import {Button, Card, CardContent, TextField} from "@mui/material";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {Link} from "@mui/icons-material";


export default function LoginPage (props){
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const defaultValues = {
        username: "",
        password: ""
    };

    const loggingIn = false
    const [formValues, setFormValues] = useState(defaultValues);

    function submit(e){
        e.preventDefault();
        console.log(formValues)
        dispatch(loginUser({name:formValues.username, pass: formValues.password}))

        navigate("/", {replace: true})
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <Card>
            <CardContent>

                <h2>Login</h2>
                <form name="form" onSubmit={submit}>
                    <Stack spacing={2} direction="column">
                        <TextField
                            id="username"
                            variant="outlined"
                            type="text"
                            className="form-control"
                            label="username"
                            name="username"
                            onChange={handleInputChange}
                        />

                        <TextField
                            id="password"
                            variant="outlined"
                            type="password"
                            className="form-control"
                            label="password"
                            name="password"
                            onChange={handleInputChange}
                        />

                        <Box>
                            <Stack spacing={2} direction={"row"}>
                                <Button variant="contained" type="submit" color="primary"  >Login</Button>
                                {loggingIn &&
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                                {true?"":()=>{return (<Button variant="contained" to="/register" color="secondary">Register</Button>)}}
                            </Stack>
                        </Box>
                    </Stack>
                </form>

            </CardContent>
        </Card>
    )


}

