import React, {useEffect} from 'react';
import {Card, CardContent, Container, Grid, IconButton, Stack, TextField, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../store";
import {requestGetGateway} from "../../store/slices/selectedGateway";
import Box from "@mui/material/Box";
import {getRelativeTime} from "../../utils/relativeTime";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function GatewayNavigation(props){

    let navigate = useNavigate();

    const gateways = useSelector(state => state.gatewayList.items)

    const gateway = gateways[props.gid]

    function navigateTo(){
        const target = "/gateways/"
        console.log("move to " + target)
        navigate(target, {replace: true})
    }

    return (<Stack direction={"row"} justifyContent={"space-between"} >
            <Typography variant={"h5"}>{gateway.name}</Typography>
            <Typography variant={"h5"}>{props.topic}</Typography>
            <IconButton  onClick={(e)=>{navigateTo()}} >
                <ArrowBackIcon />
            </IconButton>
        </Stack>

    )
}

export default function GlobalSettingsPage() {

    const { gid } = useParams()
    console.error("gid", gid)

    const gateways = useSelector(state => state.gatewayList.items)


    const gateway = gateways[gid]

    console.error("gid", gateway)

    useEffect(() => {
        // on page load
        console.log("pageLoad")
        update()
        const interval = setInterval(()=>{update()}, 1000)

        return ()=>{
            // on page leave
            console.log("pageUnload ", interval)
            clearInterval(interval)
        }

    }, []);

    function update(){
        dispatchToStore(requestGetGateway({gid:gid}))
    }
    return (
        <Container>
            <Card>
                <CardContent>
                    <Box>
                        <GatewayNavigation gid={gid} topic={"Global Settings"}/>
                        <Grid container direction={'column'} spacing={2} justifyContent={'left'}  alignItems="left">
                            <Grid item>
                                <TextField fullWidth id='filled-basic' label='Name' variant='outlined' disabled={true}
                                           value={gateway.name} />
                            </Grid>

                            <Grid item>
                                <TextField fullWidth id='filled-basic' label='Serial Number' variant='outlined' disabled={true}
                                           value={gateway.serial_number} />
                            </Grid>

                            <Grid item>
                                <TextField fullWidth id='filled-basic' label='Last Contact' variant='outlined' disabled={true}
                                           value={getRelativeTime(gateway.last_modified)} />
                            </Grid>
                        </Grid>

                    </Box>

                </CardContent>
            </Card>
        </Container>
    );

};

