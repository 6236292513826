import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from '@mui/material/MenuItem';
import {Button, IconButton} from "@mui/material";
import { Link as RouterLink, Route, MemoryRouter } from 'react-router-dom';
import {useSelector} from "react-redux";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export default function UserMenu() {
    const userLogin = useSelector(state => state.userLogin)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const items = [
        //{needLogin: true, caption: "Profile", to:"/user_profile"},
        {needLogin: true, caption: "My Account", to:"/user_account"},
        {needLogin: true, caption: "Logout", to:"/logout"},
    ]

    return (
        <div>
            <Button
                size="medium"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleClick}
            >
                <AccountCircleOutlinedIcon />
                {userLogin.user.name}
            </Button>


            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {items.map((item, idx) => {
                    if ((item.needLogin && userLogin.loggedIn) || (!item.needLogin)) {
                        return (
                            <MenuItem onClick={handleClose} key={idx} component={RouterLink}
                                      to={item.to}>{item.caption}</MenuItem>
                        )
                    }
                })
                }

            </Menu>
        </div>
    );
}