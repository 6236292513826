import React, {useState} from 'react';
import {
    Card,
    CardContent,
    FormControl,
    IconButton,
    InputLabel,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MenuItem from "@mui/material/MenuItem";

const uploadIntervals = {
    "1s": 1,
    "2s": 2,
    "5s": 5,
    "10s": 10,
    "20s": 20,
    "50s": 50,
    "60s": 60,
    "120s": 120,

}

export const sensorListLookup = {
    "Accelerometer": {
        "dataSizeInBytes": (3*4) + 1,
        "sampleTimes": {"80ms": 0.08, "40ms": 0.04, "20ms":0.02, "10ms":0.01, "5ms":0.005, "2.5ms": 0.0025, "1.25ms": 0.00125 }
    },

    "Gyroscope": {
        "dataSizeInBytes": (3*4) + 1,
        "sampleTimes": {"40ms": 0.04, "20ms":0.02, "10ms":0.01, "5ms":0.005, "2.5ms": 0.0025, "1.25ms": 0.00125 }
    },

    "Magnetometer": {
        "dataSizeInBytes": (3*4) + 1,
        "sampleTimes": {"500ms": 0.5, "100ms":0.1, "50ms":0.05, "33.3ms":0.0333, "20ms": 0.020, "10ms": 0.010, "6.67ms": 0.00667, "5ms": 0.005 }
    },

    "Ambient Pressure": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {"10s": 10.0, "5s": 5.0, "1s": 1.0, "500ms":0.5, "250ms":0.250, "100ms":0.100, "50ms": 0.050, "20ms": 0.020 }
    },

    "Thermometer": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {"10s": 10.0, "5s": 5.0, "1s": 1.0, "500ms":0.5, "250ms":0.250, "100ms":0.100, "50ms": 0.050, "20ms": 0.020 }
    },

    "Relative Humidity Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {"10s": 10.0, "5s": 5.0, "1s": 1.0, "500ms":0.5, "250ms":0.250, "100ms":0.100, "50ms": 0.050, "20ms": 0.020 }
    },

    "CO2 Concentration Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {"60s": 60.0, "10s": 10.0, "1s": 1.0 }
    },

    "Gas ECO2/BVOC/IAQ": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {"60s": 60.0, "10s": 10.0, "1s": 1.0 }
    },

    "TVOC Concentration Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {"60s": 60.0, "10s": 10.0, "1s": 1.0 }
    },

    "Ambient Light Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {"60s": 60.0, "10s": 10.0, "1s": 1.0, "800ms": 0.800, "500ms": 0.500, "250ms": 0.250, "125ms": 0.125 }
    },
    "Barometer": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {"60s": 60.0, "10s": 10.0}
    }
}

function getUploadIntervals(){
    return Object.keys(uploadIntervals)
}

function getUploadInterval(uploadInterval){
    if (Object.keys(uploadIntervals).includes(uploadInterval)){
        return uploadIntervals[uploadInterval]
    }
    return 0
}

function getSensors(){
    return Object.keys(sensorListLookup)
}

function getSampleTimes(sensor){
    if (Object.keys(sensorListLookup).includes(sensor)){
        return Object.keys(sensorListLookup[sensor].sampleTimes)
    } else {
        return []
    }
}

function getDataSize(sensor){
    if (Object.keys(sensorListLookup).includes(sensor)){
        return sensorListLookup[sensor].dataSizeInBytes
    } else {
        return 0
    }
}

export function getSampleTime(sensor, sampleTime){
    if (Object.keys(sensorListLookup).includes(sensor)) {
        if (Object.keys(sensorListLookup[sensor].sampleTimes).includes(sampleTime)) {
            return sensorListLookup[sensor].sampleTimes[sampleTime]
        }
    }
    return 0
}

function getHeaderSize(){
    // bytes (overhead)
    return 6
}

function UploadIntervalSelector(prop) {

    const [selected, setSelected] = useState(prop.selected)

    const handleChange = (event: SelectChangeEvent) => {
        const sampleTime = event.target.value
        setSelected(sampleTime)
        prop.onHandleChange(prop.id, sampleTime)
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="upload_interval_select">Upload Interval</InputLabel>
            <Select
                value={selected}
                label={"upload_interval"}
                onChange={handleChange}

            >
                <MenuItem value={"None"}>None</MenuItem>

                {Object.entries(getUploadIntervals()).map(([name, value], idx) =>
                    <MenuItem value={value} key={idx}>{value}</MenuItem>
                )}

            </Select>
        </FormControl>
    )
}

function SampleTimeSelector(prop) {

    const [selected, setSelected] = useState(prop.selected)

    const handleChange = (event: SelectChangeEvent) => {
        const sampleTime = event.target.value
        setSelected(sampleTime)
        prop.onHandleChange(prop.id, sampleTime)
    };

    const validate = selected => {
        const sampleTimes = getSampleTimes(prop.sensor)
        if (sampleTimes.includes(selected) || (selected === "None")){
            return selected
        } else {
            setSelected("None")
            prop.onHandleChange(prop.id, "None")
            return "None"
        }
    }

    console.log("SampleTimeSelector - selected", prop.sensor)
    return (
        <FormControl fullWidth>
            <InputLabel id="update_time_select">Sample Time</InputLabel>
            <Select
                value={validate(selected)}
                label={"update_time"}
                onChange={handleChange}

            >
                <MenuItem value={"None"}>None</MenuItem>

                {Object.entries(getSampleTimes(prop.sensor)).map(([name, value], idx) =>
                    <MenuItem value={value} key={idx}>{value}</MenuItem>
                )}
            </Select>
        </FormControl>
    )
}

function SensorSelector(prop) {

    const [selected, setSelected] = useState(prop.selected)

    const handleChange = (event: SelectChangeEvent) => {
        const sensor = event.target.value
        setSelected(sensor)
        prop.onHandleChange(prop.id, sensor)
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="sensor_select">Sensor</InputLabel>
            <Select
                value={selected}
                label={"sensor"}
                onChange={handleChange}

            >
                <MenuItem value={"None"}>None</MenuItem>

                {Object.entries(getSensors()).map(([name, value], idx) =>
                    <MenuItem value={value} key={idx}>{value}</MenuItem>
                )}

            </Select>
        </FormControl>
    )
}

export default function CalculatorPage() {

    const [sensors, setSensors] = useState([])
    const [editRow, setEditRow] = useState(-1)

    const handleAddRow = add => {
        setSensors([...sensors, {
            sensor: "None",
            sampleTime: "None",
            uploadInterval: "None",
            bandWidth: 0
        }])
    }

    const handleDeleteItem = id => {
        console.log("removing", id)
        const remainingItems = [...sensors]
        remainingItems.splice(id, 1)
        console.log(remainingItems)
        setSensors(remainingItems);
    }

    const handleEditRow = id => {
        setEditRow(id)
    }

    const handleEditRowDone = id => {
        setEditRow(-1)
    }

    const recalcBandWidth = (sensor, sampleTime, uploadInterval) => {
        console.log(sensor, "dataSize:", getDataSize(sensor), "sampleTime:" ,sampleTime, getSampleTime(sensor, sampleTime), "uploadInterval", getUploadInterval(uploadInterval))
        const dataRate = getDataSize(sensor) * 1 / getSampleTime(sensor, sampleTime) + getHeaderSize() / getUploadInterval(uploadInterval)
        // \return: bytes / s
        return dataRate
    }

    const handleSetSensor = (id, sensor) => {
        console.log("set sensor ", id, sensor)
        const modItems = [...sensors]
        modItems[id].sensor = sensor
        modItems[id].bandWidth = recalcBandWidth(sensor, modItems[id].sampleTime, modItems[id].uploadInterval)
        setSensors(modItems)
        console.log(modItems)
    }

    const handleSetSampleTime = (id, sampleTime) => {
        console.log("set sensor ", id, sampleTime)
        const modItems = [...sensors]
        modItems[id].sampleTime = sampleTime
        modItems[id].bandWidth = recalcBandWidth(modItems[id].sensor, sampleTime, modItems[id].uploadInterval)
        setSensors(modItems)
        console.log(modItems)
    }

    const handleSetUploadInterval = (id, uploadInterval) => {
        console.log("set sensor ", id, uploadInterval)
        const modItems = [...sensors]
        modItems[id].uploadInterval = uploadInterval
        modItems[id].bandWidth = recalcBandWidth(modItems[id].sensor, modItems[id].sampleTime, uploadInterval)
        setSensors(modItems)
        console.log(modItems)
    }

    function totalBandwidth(){
        let total = 0
        Object.entries(sensors).forEach(([idx, s]) => {
            total += s.bandWidth
        })
        return total
    }

    return (
        <Card>

            <CardContent>
                <Typography variant={"h3"}>Bandwidth Calculator</Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Sensor</TableCell>
                            <TableCell>Sample Time</TableCell>
                            <TableCell>Upload Interval</TableCell>
                            <TableCell>est. Data Rate</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sensors.length ? Object.entries(sensors).map(([name, value], id) => (
                            <TableRow key={id}>
                                <TableCell>{id}</TableCell>

                                <TableCell>{id === editRow ?
                                    <SensorSelector id={id} onHandleChange={handleSetSensor} selected={value.sensor}/> : value.sensor}</TableCell>

                                <TableCell>{id === editRow ?
                                    <SampleTimeSelector id={id} selected={value.sampleTime} sensor={value.sensor} onHandleChange={handleSetSampleTime}/>
                                    : value.sampleTime}</TableCell>

                                <TableCell>{id === editRow ?
                                    <UploadIntervalSelector id={id} selected={value.uploadInterval} onHandleChange={handleSetUploadInterval}/>
                                    : value.uploadInterval}</TableCell>

                                <TableCell>{value.bandWidth} Bytes/s</TableCell>

                                <TableCell>
                                    {editRow === -1 ?
                                        <div>
                                            <IconButton color={"primary"} onClick={() => handleEditRow(id)}><EditIcon/></IconButton>
                                            <IconButton color={"primary"}
                                                        onClick={() => handleDeleteItem(id)}><DeleteForeverIcon/></IconButton>
                                        </div>
                                        :
                                        <div>
                                            {id === editRow ?
                                                <IconButton color={"primary"}
                                                            onClick={() => handleEditRowDone(id)}><CheckCircleIcon/></IconButton>
                                                :
                                                <></>
                                            }
                                        </div>

                                    }
                                </TableCell>
                            </TableRow>
                        )) : <></>}

                        <TableRow key={"total"}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell><Typography variant={"subtitle2"}>Total</Typography></TableCell>
                            <TableCell><Typography variant={"subtitle2"}>{totalBandwidth()} Bytes/s</Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>


                    </TableBody>

                </Table>

                <IconButton color={"primary"} size={"large"} onClick={handleAddRow}><AddCircleIcon/></IconButton>

            </CardContent>
        </Card>
    );

};

