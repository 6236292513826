import {history} from '../../App'

import { put, select, call } from 'redux-saga/effects';

import {recaderoRequest} from "./recaderoApi";
import {responseGetGateway} from "../slices/selectedGateway";

export function * getGatewayRequest(action){

    const result = yield recaderoRequest("get_gateway", {gateway_id: action.payload.gid})

    console.log(result)

    if (!result){
        yield put(responseGetGateway({}))
    } else {
        yield put(responseGetGateway(result.details))
    }

}
